// ! Only import images that are expected to be in the
// viewport on initial load, these will have the blurDataURL
// handled by next
import type { ShopItemStaticImports } from './types'
// apps/shop/public/assets/images/deals/backgrounds/card/:
import mysterychestsmartboost_kappaImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_kappa.png'
import mysterychestsmartboost_kappaprideImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_kappapride.png'
import mysterychestsmartboost_lulImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_lul.png'
import mysterychestsmartboost_pogchampImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_pogchamp.png'
import mysterychestsmartboost_smorcImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_smorc.png'
import mysterychestsmartboost_trihardImgcard from '@/shop/public/assets/images/deals/backgrounds/card/mysterychestsmartboost_trihard.png'
import maxbulkvoucherImgcard from '@/shop/public/assets/images/deals/backgrounds/card/maxbulkvoucher.png'
import gemboostImgcard from '@/shop/public/assets/images/deals/backgrounds/card/gemboost.png'
import cryptonboostImgcard from '@/shop/public/assets/images/deals/backgrounds/card/cryptonboost.png'
// Same as maxbulk
import athenepacksImgcard from '@/shop/public/assets/images/deals/backgrounds/card/maxbulkvoucher.png'
import goldenboostImgcard from '@/shop/public/assets/images/deals/backgrounds/card/goldenboost.png'
// Same as goldenboost
import megatreasureboostImgcard from '@/shop/public/assets/images/deals/backgrounds/card/goldenboost.png'
import bootcampboostImgcard from '@/shop/public/assets/images/deals/backgrounds/card/bootcampboost.png'

// apps/shop/public/assets/images/deals/backgrounds/frontpage/:
import exclusivemodelboost_kappaImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_kappa.jpg'
import exclusivemodelboost_kappaprideImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_kappapride.jpg'
import exclusivemodelboost_lulImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_lul.jpg'
import exclusivemodelboost_pogchampImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_pogchamp.jpg'
import exclusivemodelboost_smorcImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_smorc.jpg'
import exclusivemodelboost_trihardImgfrontpage from '@/shop/public/assets/images/deals/backgrounds/frontpage/exclusivemodelboost_trihard.jpg'

// apps/shop/public/assets/images/deals/front/:
import exclusivemodelplaceholderImgfront from '@/shop/public/assets/images/deals/front/exclusivemodelplaceholder.png'

const shopItemStaticImport: ShopItemStaticImports = {
  'bg-card': {
    athenepacks: athenepacksImgcard,
    bootcampboost: bootcampboostImgcard,
    cryptonboost: cryptonboostImgcard,
    gemboost: gemboostImgcard,
    goldenboost: goldenboostImgcard,
    maxbulkvoucher: maxbulkvoucherImgcard,
    megatreasureboost: megatreasureboostImgcard,
    mysterychestsmartboost_kappa: mysterychestsmartboost_kappaImgcard,
    mysterychestsmartboost_kappapride: mysterychestsmartboost_kappaprideImgcard,
    mysterychestsmartboost_lul: mysterychestsmartboost_lulImgcard,
    mysterychestsmartboost_pogchamp: mysterychestsmartboost_pogchampImgcard,
    mysterychestsmartboost_smorc: mysterychestsmartboost_smorcImgcard,
    mysterychestsmartboost_trihard: mysterychestsmartboost_trihardImgcard,
  },
  'bg-frontpage': {
    exclusivemodelboost_kappa: exclusivemodelboost_kappaImgfrontpage,
    exclusivemodelboost_kappapride: exclusivemodelboost_kappaprideImgfrontpage,
    exclusivemodelboost_lul: exclusivemodelboost_lulImgfrontpage,
    exclusivemodelboost_pogchamp: exclusivemodelboost_pogchampImgfrontpage,
    exclusivemodelboost_smorc: exclusivemodelboost_smorcImgfrontpage,
    exclusivemodelboost_trihard: exclusivemodelboost_trihardImgfrontpage,
  },
  front: {
    exclusivemodelplaceholder: exclusivemodelplaceholderImgfront,
  },
} as const

export default shopItemStaticImport
