import weightedSample from '@g4g/utils/src/weightedSample'
import pluck from '@g4g/utils/src/pluck'

/**
 * Some of the shopItemIds roughly weighted based on
 * number of time purchased.
 */
type WeightedSectionShopItem = { shopItemId: string; weight: number; backup?: boolean }
export const weightedUpperSection: WeightedSectionShopItem[] = [
  { shopItemId: 'goldenboost', weight: 0.35, backup: true },
  { shopItemId: 'maxbulkvoucher', weight: 0.35, backup: true },
  { shopItemId: 'questcompletionprize', weight: 0.15, backup: true },
  { shopItemId: 'exclusivemodelboost', weight: 0.1, backup: true },
  { shopItemId: 'singlemysterychestsmartboost', weight: 0.05 },
]
export const weightedShopOnlySection: WeightedSectionShopItem[] = [
  { shopItemId: 'landvoucher', weight: 0.25, backup: true },
  { shopItemId: 'monthlybuildingskin', weight: 0.25, backup: true },
  { shopItemId: 'monthlyheroskin', weight: 0.25, backup: true },
  { shopItemId: 'monthlypetskin', weight: 0.25, backup: true },
]
export const weightedBottomSection: WeightedSectionShopItem[] = [
  { shopItemId: 'yearlycryptondrops', weight: 0.05 },
  { shopItemId: 'accountpowerlevelboost', weight: 0.5, backup: true },
  { shopItemId: '90percentoffboost', weight: 0.15, backup: true },
  { shopItemId: 'dailysales', weight: 0.15 },
  { shopItemId: 'cryptonboost', weight: 0.05, backup: true },
  { shopItemId: 'insanityboost', weight: 0.05, backup: true },
  { shopItemId: 'bestgame', weight: 0.05, backup: true },
]
export const weightedOtherSection: WeightedSectionShopItem[] = [
  { shopItemId: 'timedrewardboost', weight: 0.35 },
  { shopItemId: 'mysterychestsmartboost', weight: 0.25 },
  { shopItemId: 'bonusbank', weight: 0.25 },
  { shopItemId: 'trihardboost', weight: 0.05 },
  { shopItemId: 'megatreasureboost', weight: 0.05 },
  { shopItemId: 'benefits', weight: 0.05 },
]

const getSampleFromSection = (s: WeightedSectionShopItem[]) =>
  weightedSample(pluck(s, 'shopItemId'), pluck(s, 'weight'))
export const getBackupSectionShopItemIds = () =>
  pluck(
    [...weightedUpperSection, ...weightedShopOnlySection, ...weightedBottomSection].filter(
      (s) => s.backup
    ),
    'shopItemId'
  )
/**
 * @returns a random shopItemId for every section based on a weight
 */
export const getSampleFromEverySection = () => [
  getSampleFromSection(weightedUpperSection),
  getSampleFromSection(weightedShopOnlySection),
  getSampleFromSection(weightedBottomSection),
  getSampleFromSection(weightedOtherSection),
]
// frontpage top
export const largeWideSectionShopItemId = () => pluck(weightedUpperSection, 'shopItemId')[0]
export const shopOnlySectionShopItemIds = () =>
  pluck(weightedShopOnlySection, 'shopItemId').slice(1)
export const shopOnlyWideSectionShopItemId = () => pluck(weightedShopOnlySection, 'shopItemId')[0]
export const popularSectionShopItemIds = () => pluck(weightedUpperSection, 'shopItemId').slice(1)
// frontpage bottom
export const smallWideSectionShopItemId = () => pluck(weightedBottomSection, 'shopItemId')[0]
export const browseSectionShopItemIds = () => pluck(weightedBottomSection, 'shopItemId').slice(1)
