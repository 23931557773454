import type { FC } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useShopSearchOpsDispatch } from './ShopSearchProvider'
import ClientOnly from '@g4g/ui/src/utility/ClientOnly'

const ShopSearchTrigger: FC = () => {
  const dispatch = useShopSearchOpsDispatch()
  return (
    <div className="flex items-center w-full px-4 py-4 text-white rounded-lg lg:bg-white lg:py-2 sm:space-x-2 lg:text-white/70 text-white/70 hover:text-white/90 lg:bg-opacity-10">
      {/* For some reason this breaks w/o ClientOnly, and it didn't seem to be caused by localStorage. */}
      <ClientOnly>
        <button
          className="w-full h-full flex items-center sm:space-x-2"
          data-cy="shop-search-trigger"
          role="button"
          aria-label="Search Shop Deals"
          onClick={() => dispatch({ type: 'set-is-open', isOpen: true })}
        >
          <div className="w-5 h-5">
            <MagnifyingGlassIcon aria-hidden />
          </div>
          <span className="sr-only sm:not-sr-only xl:not-sr-only lg:sr-only">Search</span>
        </button>
      </ClientOnly>
    </div>
  )
}

export default ShopSearchTrigger
