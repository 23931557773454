import { getShopItemCategory } from '@/shop/src/utils/shopItemUtils'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { TickFilter } from '@g4g/utils/src/types'
import type { DealFiltersTickTrait } from '../../deals/provider/types'
import { tickFilters } from '@g4g/utils/src/gameDataUtils'
import { isProduct as isSearchResultProduct } from '../../shopping-cart/util/cart'
import type { ShopSearchResult } from '../search/util/types'
import { shopItemImageSrc } from '../ShopItemImage'
import { useActiveRecipientShopItems } from './useActiveRecipientShopItems'
import includesCaseInsensitive from '@g4g/utils/src/includesCaseInsensitive'
import uniqueElementsBy from '@g4g/utils/src/uniqueElementsBy'
import groupTo2DBy from '@g4g/utils/src/groupTo2DBy'
import pluralize from '@g4g/utils/src/pluralize'
import { RewardType } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import {
  getBackupSectionShopItemIds,
  getSampleFromEverySection,
} from '@/shop/src/utils/shopItemSections'
import { useShopSearch } from '../search/ShopSearchProvider'

// Randomly pick some of the most popular deals
// to be displayed for default search results
// (the more popular ones should get picked more often)
const suggestedDealSamples = getSampleFromEverySection()
// How many deals should be suggested
const suggestedCount = suggestedDealSamples.length
// Suggest more from this list in case picked deal is not available
const backupDealSamples = getBackupSectionShopItemIds()
// Always display these resource filters below
// the picked deals in the default search results
const suggestedResources = [
  RewardType.Crypton,
  RewardType.Gem,
  RewardType.SeasonalEventItem,
  RewardType.SalePoints,
]
const { category, resource } = tickFilters()

const searchResultHref = (isProduct: boolean, slug: string, trait?: DealFiltersTickTrait) =>
  `/deals${isProduct ? `/${slug}` : `?${trait}=${slug}`}`

const shopItemToSearchResult = (
  s: GetShopItems_getShopItems,
  label?: string
): Omit<ShopSearchResult, 'name' | 'isProduct'> => ({
  id: s.shopItemId,
  type: 'product',
  href: searchResultHref(true, s.kebabName),
  label: label ?? getShopItemCategory(s),
  imageSrc: shopItemImageSrc('front', s),
})

const constructSearchResult = (result: GetShopItems_getShopItems | TickFilter, label?: string) => {
  const { name } = result
  const isProduct = isSearchResultProduct(result)
  if (isProduct) {
    return { name, ...shopItemToSearchResult(result, label) }
  }
  const { id, iconPath } = result
  const isResource = !!resource.find((filter) => filter.id === id)
  const trait: DealFiltersTickTrait = isResource ? 'resource' : 'category'
  return {
    id,
    name,
    imageSrc: iconPath,
    href: searchResultHref(isProduct, id, trait),
    type: trait,
    label: label ?? pluralize(2, trait),
  }
}

const canSuggestDeal = (item: GetShopItems_getShopItems, allowed: string[], duplicates: string[]) =>
  allowed.includes(item.shopItemId) &&
  item.limit?.remaining !== 0 &&
  !duplicates.includes(item.shopItemId)

const sortBySamples = (dealResults: ShopSearchResult[], samples: string[]) =>
  dealResults.sort((a, b) => samples.indexOf(a.id) - samples.indexOf(b.id))

const constructSearchResults = <T extends GetShopItems_getShopItems | TickFilter>(
  items: T[],
  fn: (value: T) => boolean,
  label?: string
): ShopSearchResult[] =>
  items.filter((val) => fn(val)).map((result) => constructSearchResult(result, label))

const constructDefaultDealResults = (
  label: 'suggested' | 'recent',
  deals: GetShopItems_getShopItems[],
  samples: string[],
  fn: (value: GetShopItems_getShopItems) => boolean
) => sortBySamples(constructSearchResults(deals, fn, label), samples)

const handPickDefaultResults = (deals: GetShopItems_getShopItems[], recent: string[]) => {
  // In case some of the picked samples are missing
  // we fill the rest with backup deals, to always have
  // <suggestedCount> deals
  let backupDealResults: ShopSearchResult[] = []
  const pickedDealResults = constructDefaultDealResults(
    'suggested',
    deals,
    suggestedDealSamples,
    (item) => canSuggestDeal(item, suggestedDealSamples, recent)
  )

  // We need this many more to display the desired number
  // of search results
  const missingCount = suggestedCount - pickedDealResults.length
  if (missingCount > 0) {
    backupDealResults = constructDefaultDealResults('suggested', deals, backupDealSamples, (item) =>
      canSuggestDeal(item, backupDealSamples, [...recent, ...suggestedDealSamples])
    ).slice(0, missingCount)
  }

  return [
    ...constructDefaultDealResults('recent', deals, recent, (item) =>
      recent.includes(item.shopItemId)
    ),
    ...backupDealResults,
    ...pickedDealResults,
    ...constructSearchResults(resource, (item) =>
      suggestedResources.map((r) => r.toLowerCase()).includes(item.id)
    ),
  ]
}

/**
 * @returns found filters or deals based on a search query
 */
const toSearchResults = (query: string, deals: GetShopItems_getShopItems[], recent: string[]) => {
  // Bundles grouped to a single container
  const uniqueDeals = uniqueElementsBy(deals, (a, b) => a.shopItemId === b.shopItemId)
  return query
    ? // Search query results
      constructSearchResults([...resource, ...uniqueDeals, ...category], (item) =>
        includesCaseInsensitive(item.name, query)
      )
    : // Default results
      handPickDefaultResults(uniqueDeals, recent)
}

export const useShopSearchResults = () => {
  const deals = useActiveRecipientShopItems().data?.getShopItems
  const { recent, searchQuery } = useShopSearch()

  return {
    results: groupTo2DBy(
      toSearchResults(searchQuery, deals ?? [], recent),
      (result) => result.label
    ),
    isDefault: searchQuery === '',
  }
}
