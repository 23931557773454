import { useRef, useEffect } from 'react'

/**
 * a better alternative to this should callback refs
 *
 * @see https://tkdodo.eu/blog/avoiding-use-effect-with-callback-refs
 * @note focusing with callback refs doesn't seem to work
 * with the headless `Combobox.Input`, neither does `autoFocus` attr
 */
const useAutoFocus = <T extends HTMLElement>() => {
  const inputRef = useRef<T>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return inputRef
}

export default useAutoFocus
