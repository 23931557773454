import { FC, Fragment, ReactNode, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { classNames } from '@g4g/utils/src/react/class-names'

const HeadlessModal: FC<{
  isOpen: boolean
  closeModal: () => void
  stickToTop?: boolean
  hideXButton?: boolean
  mobileOnly?: boolean
  title?: string | JSX.Element
  maxWidthClass?: string
  modalFooter?: JSX.Element
  children?: ReactNode
  dialogParentClass?: string
}> = ({
  title,
  isOpen,
  closeModal,
  children,
  modalFooter,
  mobileOnly,
  hideXButton,
  stickToTop,
  dialogParentClass,
}) => {
  // https://headlessui.dev/react/dialog#managing-focus-within-your-dialog
  // For accessibility reasons, we need at least one focusable element
  // Some of the dialogs we have won't have that, i.e. Wallet Modal
  // cause it's content is dynamically imported and a spinner is displayed
  // till it's loaded.
  //
  // If u remove this u might get a warning in the console
  // saying Error: There are no focusable elements inside the <FocusTrap />.
  const refDiv = useRef(null)

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        onClose={() => {
          // This needs to be true and not call closeModal()
          // when modalFooter is used, otherwise it causes
          // the modal to be closed when clicking anywhere inside
          // the footer element.
          // note: currently this hack only works for mobile
          // where we have a full screen modal & we don't care about
          // closing the modal by clicking the overlay or hitting esc
          !mobileOnly && closeModal()
        }}
        as="div"
        className="relative z-100"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/70" />
        </Transition.Child>

        <div
          className={classNames(
            dialogParentClass,
            'fixed inset-0',
            stickToTop && 'lg:inset-x-0 lg:bottom-auto'
          )}
        >
          <div
            className={classNames(
              'flex items-center justify-center h-full',
              !stickToTop && 'lg:p-4'
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom={classNames('opacity-0', stickToTop ? 'scale-95 lg:scale-100' : 'scale-95')}
              enterTo={classNames(
                'opacity-100',
                stickToTop ? 'scale-100 lg:scale-100' : 'scale-100'
              )}
              leave="ease-in duration-200"
              leaveFrom={classNames(
                'opacity-100',
                stickToTop ? 'scale-100 lg:scale-100' : 'scale-100'
              )}
              leaveTo={classNames('opacity-0', stickToTop ? 'scale-95 lg:scale-100' : 'scale-95')}
            >
              <div
                className={classNames(
                  'w-full h-full lg:h-min',
                  !stickToTop && 'lg:max-w-lg',
                  !modalFooter && 'overflow-y-auto'
                )}
              >
                <Dialog.Panel
                  className={classNames(
                    modalFooter ? 'pb-24' : 'pb-6',
                    stickToTop
                      ? 'p-0 lg:container lg:p-1 lg:mt-1 bg-standardBg lg:bg-transparent'
                      : 'px-6 pt-6 bg-standardBg lg:shadow-2xl shadow-black lg:rounded-2xl',
                    'h-screen transform lg:h-min overflow-y-auto'
                  )}
                >
                  {title && (
                    <Dialog.Title
                      as="h3"
                      className="pt-3 mx-4 mb-6 text-xl font-bold leading-6 text-center text-white lg:mb-2 lg:pt-4 font-poppins"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  {!hideXButton && (
                    <button
                      role="button"
                      aria-label="close modal"
                      data-cy="dialog-close-x"
                      className="absolute top-0 right-0 p-4 text-white cursor-pointer lg:text-white/80 lg:hover:text-white"
                      onClick={() => closeModal()}
                    >
                      <div className="w-5 h-5">
                        <XMarkIcon />
                      </div>
                    </button>
                  )}
                  <div className="flex flex-col justify-between" ref={refDiv}>
                    <>{children}</>
                  </div>
                </Dialog.Panel>
                {modalFooter && (
                  <div className="fixed bottom-0 left-0 right-0 flex justify-end p-4 w-full bg-[#181c25] border-t border-gray-600/20 lg:rounded-bl-2xl">
                    {modalFooter}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default HeadlessModal
