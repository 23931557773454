import type { ReactNode } from 'react'
import Image from '../utility/Image'
import koragFadedImg from '../../public/shared-assets/img/korag_faded.webp'
import { classNames } from '@g4g/utils/src/react/class-names'

const PlaceholderKorag: React.FC<{ callToAction?: ReactNode; children?: ReactNode }> = ({
  callToAction,
  children,
}) => (
  <div className="flex justify-center w-full" data-cy="korag">
    <div className="flex flex-col w-full">
      <div className="flex mx-auto opacity-40">
        <Image src={koragFadedImg} width={130} height={130} alt="Korag" />
      </div>
      <p
        className={classNames(
          'mx-auto text-sm font-bold leading-5',
          'text-center transform -translate-y-3',
          'md:text-md text-secondaryLight font-poppins'
        )}
      >
        {children}
      </p>
      {callToAction && <div className="inline pt-2 m-auto">{callToAction}</div>}
    </div>
  </div>
)

export default PlaceholderKorag
