/**
 * @returns a random element from an array,
 * using the provided `weights` as the probabilities for each element.
 * @example
 * weightedSample([3, 7, 9, 11], [0.1, 0.2, 0.6, 0.1]); // 9
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/weightedSample.md
 */
const weightedSample = <T>(arr: T[], weights: number[]) => {
  let roll = Math.random()
  return arr[
    weights
      .reduce((acc, w, i) => (i === 0 ? [w] : [...acc, acc[acc.length - 1] + w]), [] as number[])
      .findIndex((v, i, s) => roll >= (i === 0 ? 0 : s[i - 1]) && roll < v)
  ]
}

export default weightedSample
