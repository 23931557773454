import type { FC, KeyboardEvent } from 'react'
import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon, ChevronLeftIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { classNames } from '@g4g/utils/src/react/class-names'
import { ShopSearchResult } from './util/types'
import { useShopSearch, useShopSearchOpsDispatch } from './ShopSearchProvider'
import useAutoFocus from '@g4g/utils/src/react/useAutoFocus'

const ShopMagnifyingGlassIcon: FC = () => (
  <div className="absolute hidden w-5 h-5 lg:flex top-6 left-4 lg:w-6 lg:h-6 lg:top-3 lg:left-6 text-white/50">
    <MagnifyingGlassIcon />
  </div>
)

const ShopSearchBackButton: FC = () => {
  const dispatch = useShopSearchOpsDispatch()

  return (
    <div
      onClick={() => dispatch({ type: 'set-is-open', isOpen: false })}
      className="absolute top-0 left-0 flex items-center justify-center w-12 h-16 cursor-pointer lg:hidden text-white/50 hover:text-white"
    >
      <ChevronLeftIcon className="w-6 h-6" />
    </div>
  )
}

const ShopSearchInput: FC = () => {
  const { searchQuery } = useShopSearch()
  const dispatch = useShopSearchOpsDispatch()
  const inputRef = useAutoFocus<HTMLInputElement>()

  return (
    <>
      <ShopSearchBackButton />
      <ShopMagnifyingGlassIcon />
      <Combobox.Input
        autoComplete="off"
        ref={inputRef}
        data-cy="shop-search-input"
        placeholder="Search deals, resources or categories"
        // Close the search when pressing the escape key
        // while the input is focused
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Escape') {
            dispatch({ type: 'set-is-open', isOpen: false })
          }
        }}
        className={classNames(
          'w-full lg:ring-2 lg:ring-gray-600 text-white h-16 lg:h-10',
          'focus:outline-none lg:focus:border-gray-500',
          'lg:rounded-lg lg:focus:ring-2 lg:focus:ring-gray-500',
          'placeholder:text-white/50 bg-[#212733]',
          'px-12 lg:px-16 text-sm sm:text-base'
        )}
        // Can be undefined with Combobox nullable
        displayValue={(result: ShopSearchResult) => result?.name}
        onChange={({ target: { value } }) =>
          dispatch({ type: 'update-search-query', searchQuery: value })
        }
      />
      {searchQuery && (
        <div
          data-cy="shop-search-clear-input"
          onClick={() => {
            inputRef.current?.focus()
            dispatch({ type: 'update-search-query', searchQuery: '' })
          }}
          className={classNames(
            'absolute top-0 right-0 flex items-center lg:right-6',
            'justify-center w-12 h-16 lg:w-6 lg:h-6 cursor-pointer',
            'text-white/50 hover:text-white lg:top-3'
          )}
        >
          <XMarkIcon className="w-4 h-4" />
        </div>
      )}
    </>
  )
}

export default ShopSearchInput
