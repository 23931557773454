import type { ShopItemAsset, ShopItemFallbacks, ShopItemFrontPageImports } from './types'
import { SkinShopItemId } from '@g4g/utils/src/types'
import { isValidHttpUrl } from '@g4g/utils/src/urlUtils'
import shopItemImg from './shopItemStaticImports'

const defaultBgCardBlurData =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAMAAAACh/xsAAAADFBMVEUeHh4nJycNDQ0wMDBhjjioAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAJklEQVQImWNgggIGBGBkZmZmZkRiMDIyMjMzgkUYmRnBDBATSAIACUgAQuNA3pAAAAAASUVORK5CYII='

const hasStaticImport = (type: ShopItemAsset, key: string) =>
  Object.prototype.hasOwnProperty.call(shopItemImg[type], key)

const distinctPath: { [k in ShopItemAsset]: string } = {
  'bg-frontpage': '/backgrounds/frontpage',
  'bg-card': '/backgrounds/card',
  front: '/front',
} as const

const imgType: { [k in ShopItemAsset]: string } = {
  'bg-frontpage': 'jpg',
  'bg-card': 'png',
  front: 'png',
} as const

const resolveStaticSrc = (type: ShopItemAsset, key: string) =>
  hasStaticImport(type, key)
    ? shopItemImg[type][key]
    : `/assets/images/deals${distinctPath[type]}/${key}.${imgType[type]}`

export const resolveBgCardBlurDataURL = (shopItemId: string, suffix?: string | null) => {
  const autoBlur =
    hasStaticImport('bg-card', shopItemId) || hasStaticImport('bg-card', `${shopItemId}_${suffix}`)

  return autoBlur ? undefined : defaultBgCardBlurData
}

export const frontPageBgs: ShopItemFrontPageImports = {
  [SkinShopItemId.ExclusiveModelBoost]: {
    kappa: shopItemImg['bg-frontpage']['exclusivemodelboost_kappa'],
    kappapride: shopItemImg['bg-frontpage']['exclusivemodelboost_kappapride'],
    lul: shopItemImg['bg-frontpage']['exclusivemodelboost_lul'],
    pogchamp: shopItemImg['bg-frontpage']['exclusivemodelboost_pogchamp'],
    smorc: shopItemImg['bg-frontpage']['exclusivemodelboost_smorc'],
    trihard: shopItemImg['bg-frontpage']['exclusivemodelboost_trihard'],
  },
} as const

const fallbacks: ShopItemFallbacks = {
  [SkinShopItemId.ExclusiveModelBoost]: {
    'bg-frontpage': shopItemImg['bg-frontpage']['exclusivemodelboost_smorc'],
    'bg-card': shopItemImg['bg-card']['mysterychestsmartboost_smorc'],
    front: shopItemImg['front']['exclusivemodelplaceholder'],
  },
  [SkinShopItemId.MysteryChestSmartBoost]: {
    'bg-frontpage': shopItemImg['bg-frontpage']['exclusivemodelboost_pogchamp'],
    'bg-card': shopItemImg['bg-card']['mysterychestsmartboost_pogchamp'],
    front: shopItemImg['front']['exclusivemodelplaceholder'],
  },
} as const

export const constructImageSrc = (
  img: string | null | undefined,
  type: ShopItemAsset,
  shopItemId: string
) => {
  // We got a null from the API so take the
  // /assets/images/../{shopItemId}.png|jpg image
  if (!img) {
    // Fallback front page background for the showcased deal
    if (fallbacks[shopItemId]) {
      return fallbacks[shopItemId][type]
    }

    return resolveStaticSrc(type, shopItemId)
  }

  // The API returned an external URL to the image
  if (isValidHttpUrl(img)) {
    return img
  }

  // A certain suffix is expected here to select the image
  // from /assets/images/../{shopItemId}_{suffix}.png|jpg dynamically
  return resolveStaticSrc(type, `${shopItemId}_${img}`)
}
