import type { FC } from 'react'
import Image from 'next/image'
import type { GroupedSearchResults, ShopSearchResult } from './util/types'
import { Combobox } from '@headlessui/react'
import { classNames } from '@g4g/utils/src/react/class-names'

const FoundItem: FC<{ result: ShopSearchResult }> = ({ result: { name, imageSrc } }) => (
  <div className="flex w-full">
    <div className="flex items-center space-x-4">
      {imageSrc && <Image src={imageSrc} width={30} height={30} alt="" />}
      <span data-cy="shop-search-item-name" className="font-bold lg:text-lg">
        {name}
      </span>
    </div>
  </div>
)

const ShopSearchOptions: FC<{ searchResults: GroupedSearchResults }> = ({ searchResults }) => (
  <Combobox.Options
    className="w-full lg:mt-1 lg:rounded-lg bg-standardBg lg:max-h-[400px]"
    // Always display some search results even if
    // the search query is empty
    static
  >
    {searchResults.map(([label, results]) => (
      <div key={label ?? 'default'}>
        {label && (
          <Combobox.Label className="flex w-full px-4 pt-4 pb-2 text-xs text-white/50 lg:bg-standardBg">
            {label}
          </Combobox.Label>
        )}
        {results.map((r) => (
          <Combobox.Option
            key={r.id}
            data-cy="shop-search-found-result"
            className={({ active }) =>
              classNames(
                'relative cursor-pointer select-none p-4 lg:last:rounded-b-lg',
                active ? 'bg-gray-700' : 'lg:bg-standardBg'
              )
            }
            value={r}
          >
            <FoundItem result={r} />
          </Combobox.Option>
        ))}
      </div>
    ))}
  </Combobox.Options>
)

export default ShopSearchOptions
