/**
 * @returns true if a string contains a substring, case-insensitive.
 * @example
 * includesCaseInsensitive('Blue Whale', 'blue'); // true
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/includesCaseInsensitive.md
 */
const includesCaseInsensitive = (str: string, searchString: string) =>
  new RegExp(searchString, 'i').test(str)

export default includesCaseInsensitive
