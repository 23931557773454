/**
 * @returns grouped elements of an array based on a given
 * key retriever function
 * @example
 * groupTo2DBy([
 *   { id: '1' },
 *   { id: '2' },
 *   { id: '1' },
 *   { name: 'abc' }
 * ], (item => item.id))
 * // [
 * //   [ '1', {id: '1'}, {id: '1' } ],
 * //   [ '2', { id: '2' } ],
 * //   [ undefined, {name: 'abc' } ]
 * // ]
 */
const groupTo2DBy = <T>(arr: T[], fn: (t: T) => string | undefined) =>
  Array.from(
    arr.reduce((entryMap, e) => {
      const k = fn(e)
      return entryMap.set(k, [...(entryMap.get(k) || []), e])
    }, new Map<string | undefined, T[]>())
  )

export default groupTo2DBy
