import { FC } from 'react'
import PlaceholderKorag from '@g4g/ui/src/internal/PlaceholderKorag'

const NoResultsKorag: FC<{ heading: string; subtext: string }> = ({ heading, subtext }) => (
  <PlaceholderKorag>
    <span className="font-bold text-white lg:text-xl">{heading}</span>
    <br />
    <span>{subtext}</span>
  </PlaceholderKorag>
)

export default NoResultsKorag
