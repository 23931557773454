import type { FC } from 'react'
import ShopSearchDialog from './ShopSearchDialog'
import ShopSearchTrigger from './ShopSearchTrigger'

const ShopSearch: FC = () => (
  <>
    <ShopSearchTrigger />
    <ShopSearchDialog />
  </>
)

export default ShopSearch
