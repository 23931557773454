import { FC, useState } from 'react'
import { Combobox } from '@headlessui/react'
import ShopSearchInput from './ShopSearchInput'
import { useRouter } from 'next/router'
import { ShopSearchResult } from './util/types'
import ShopSearchResults from './ShopSearchResults'
import { useShopSearchOpsDispatch } from './ShopSearchProvider'

const ShopSearchCombobox: FC = () => {
  const router = useRouter()
  const [searchResult, setSearchResult] = useState<ShopSearchResult | null>(null)
  const dispatch = useShopSearchOpsDispatch()

  return (
    <Combobox
      nullable
      value={searchResult}
      onChange={(res) => {
        setSearchResult(res)
        if (!res) {
          return
        }
        // Searching for filters on the /deals page should
        // just toggle the filter instead of using the router
        // to navigate to the search result
        //
        // The FilterProvider should listen to these changes
        if (router.pathname === '/deals' && res.type !== 'product') {
          dispatch({ type: 'update-filter', name: res.id, trait: res.type })
          return
        }
        // Route to the selected non empty search result
        router.push(res.href)
        dispatch({ type: 'select-result', isOpen: false, id: res.id, resultType: res.type })
      }}
    >
      <ShopSearchInput />
      <ShopSearchResults />
    </Combobox>
  )
}

export default ShopSearchCombobox
