import type { FC } from 'react'
import { ImageProps, StaticImageData } from 'next/image'
import Image from 'next/image'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { ShopItemAsset } from '@/shop/src/utils/img/types'
import { constructImageSrc } from '@/shop/src/utils/img/shopItemAssets'

export const shopItemImageSrc = (
  type: ShopItemAsset,
  { assets, shopItemId }: GetShopItems_getShopItems
): string | StaticImageData => {
  const src = {
    // Front Page LCP background Image
    'bg-frontpage': constructImageSrc(assets?.bgFrontPage, 'bg-frontpage', shopItemId),
    // Deals Page Thumbnail background Image
    'bg-card': constructImageSrc(assets?.bgCard, 'bg-card', shopItemId),
    // Deals/Cart/History Page product Image, or Thumbnail front Image
    front: constructImageSrc(assets?.front, 'front', shopItemId),
  } as { [k in ShopItemAsset]: string }

  return src[type]
}

const ShopItemImage: FC<
  // src & alt are derived from the shopItem & the ShopItemAsset type
  Omit<ImageProps, 'src' | 'alt'> & { shopItem: GetShopItems_getShopItems; type: ShopItemAsset }
> = ({ shopItem, type, ...imgProps }) => (
  <Image
    {...imgProps}
    src={shopItemImageSrc(type, shopItem)}
    alt={type === 'front' ? `${shopItem.name} Icon` : ``}
  />
)

export default ShopItemImage
