/**
 * @returns Converted array of objects into an
 * array of values corresponding to the specified `key`.
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/pluck.md
 * @example
 * const simpsons = [
 *  { name: 'lisa', age: 8 },
 *  { name: 'homer', age: 36 },
 *  { name: 'marge', age: 34 },
 *  { name: 'bart', age: 10 }
 * ];
 * pluck(simpsons, 'age'); // [8, 36, 34, 10]
 */
const pluck = <T, K extends keyof T>(arr: T[], key: K) => arr.map((i) => i[key])

export default pluck
