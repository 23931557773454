import type { FC } from 'react'
import NoResultsKorag from '../NoResultsKorag'

const ShopSearchNoResults: FC = () => (
  <div
    className="w-full flex items-center justify-center mt-1 p-6 rounded-lg bg-standardBg max-h-[400px]"
    data-cy="shop-search-no-results"
  >
    <NoResultsKorag
      heading="Sorry, your search query produced no results"
      subtext="Try searching for other deals, resources or categories"
    />
  </div>
)

export default ShopSearchNoResults
