import type { FC } from 'react'
import HeadlessModal from '@g4g/ui/src/adopted/HeadlessModal'
import ShopSearchCombobox from './ShopSearchCombobox'
import { useShopSearch, useShopSearchOpsDispatch } from './ShopSearchProvider'

const ShopSearchDialog: FC = () => {
  const { isOpen } = useShopSearch()
  const dispatch = useShopSearchOpsDispatch()

  return (
    <HeadlessModal
      isOpen={isOpen}
      closeModal={() => dispatch({ type: 'set-is-open', isOpen: false })}
      hideXButton
      stickToTop
      dialogParentClass="lg:top-20"
    >
      <ShopSearchCombobox />
    </HeadlessModal>
  )
}

export default ShopSearchDialog
