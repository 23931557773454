import { FC } from 'react'
import { useShopSearchResults } from '../hooks/useShopSearchResults'
import ShopSearchNoResults from './ShopSearchNoResults'
import ShopSearchOptions from './ShopSearchOptions'

const ShopSearchResults: FC = () => {
  const { results, isDefault } = useShopSearchResults()

  if (isDefault) {
    return (
      <div data-cy="shop-search-default-results">
        <ShopSearchOptions searchResults={results} />
      </div>
    )
  }

  if (results.length === 0) {
    return <ShopSearchNoResults />
  }

  return <ShopSearchOptions searchResults={results} />
}

export default ShopSearchResults
